import React from 'react';
import GoogleMapReact from 'google-map-react';
import CustomMarker from '../custommarker/CustomMarker';
import CurrentMarker from '../currentmarker/CurrentMarker';
import './style.scss';
import useCurrentPos from '../../hooks/useCurrentPos';

//class SimpleMap extends Component {
export default function SimpleMap(props) {
	const [currentPos] = useCurrentPos();

    return (
        <div className="map__wrapper">
            <GoogleMapReact
                bootstrapURLKeys={{ key: props.apiKey }}
                center={props.center}
                zoom={props.zoom}
                options={{mapTypeId: 'roadmap'}} //roadmap,satellite
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => props.onGoogleApiLoaded(map, maps)}
                onChange={props.onChange}
            >
                { props.locations.length > 0 && props.locations.map(item =>
                    <CustomMarker
                        key={item.id}
                        locId={item.id}
                        isSelected={props.locId === item.id}
                        lat={item.lat}
                        lng={item.lng}
                        text=""
                        onChangeLocation={props.onChangeLocation}
                    />)
                }
                { currentPos.hasOwnProperty( 'lat')  &&
                    <CurrentMarker
                        key="currentmarker"
                        lat={currentPos.lat}
                        lng={currentPos.lng}
                        text=""
                    />
                }
            </GoogleMapReact>
        </div>
    );

}
