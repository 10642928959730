import React from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
//import TypoGraphy from '@material-ui/core/Typography'
import Drawer from '../drawer/Drawer';
//import LangSelect from '../langselect/LangSelect';
import IconButton from '@material-ui/core/IconButton';
import LangIcon from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import useDrawer from '../../hooks/useDrawer';

export default function Header(props) {
    const { t, i18n } = useTranslation();
    let lang = i18n.language;
    const [isDrawerOn, toggleDrawer] = useDrawer();

    return (
        <>
            <div id="main__header">
                <AppBar color="primary" position="static">
                    <Toolbar><span style={{ marginRight: '5px' }}></span>
                        <div className="main__menu-button">
                            <IconButton style={{ color: '#fff' }} onClick={toggleDrawer} aria-label={t('menu')}><MenuIcon /></IconButton>
                        </div>
                        <img className="main__logo" src="/img/logo/logo-w30.png" alt="" />
                       <h1 className="main__title">{t('main.title')}</h1>    
                    </Toolbar>
                    <ul className="nav__lang">
                            <li style={{ paddingRight: '10px' }}><div style={{marginTop:'21px'}}><LangIcon /></div> </li>
                            <li><a href={props.url.replace(/^\/[a-z]{2}\/?/, '/de/')} className={lang === 'de' ? 'current' : ''} >DE</a></li>
                            <li><a href={props.url.replace(/^\/[a-z]{2}\/?/, '/en/')} className={lang === 'en' ? 'current' : ''} >EN</a></li>
                            <li><a href={props.url.replace(/^\/[a-z]{2}\/?/, '/es/')} className={lang === 'es' ? 'current' : ''}>ES</a></li>
                            <li><a href={props.url.replace(/^\/[a-z]{2}\/?/, '/is/')} className={lang === 'is' ? 'current' : ''} >ÍS</a></li>
                        </ul>

                </AppBar>
            </div>
            <Drawer show={isDrawerOn} toggleDrawer={toggleDrawer} url={props.url} />
        </>
    );
}