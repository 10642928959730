import React from 'react';

const markerStyle = {
	Color: 'white',
	background: 'url(/img/markers/marker_black.png) no-repeat center center #3f51b5',
	WebkitTransition: 'all',
	msTransition: 'all',
	backgroundColor: 'transparent',
	display: 'inline-flex',
	border: '1px solid #777',
	width: '50px',
	height: '50px',
	textAlign: 'center',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '100%',
	transform: 'translate(-50%, -50%)'
};

const markerHoverStyle = {
	...markerStyle,
	background: 'url(/img/markers/marker-viewing.png) no-repeat center center #3f51b5',
	backgroundColor: '#43a047',
	border: 'none',
};

export default function CustomMarker(props) {

	let style = props.$hover ? markerHoverStyle : markerStyle;

	if (props.isSelected) {
		style = markerHoverStyle;
	}
	return (
		<div className="marker" style={style} onClick={() => { props.onChangeLocation(props.locId) }}>
			{props.text}
		</div>
	);
}
