import React, { useEffect } from 'react';
import Header from '../components/header/Header';
import { useTranslation } from 'react-i18next';

export default function PagePrivacy(props) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(props.params.lang);
    }, [props.params.lang, i18n]);

    if (props.params.lang === 'es') {
        return (
            <div className="page">
                <Header url={props.url} />
                <main style={{ margin: '64px auto 3em auto', maxWidth: '800px', padding: '0px', fontSize: '120%' }}>
                    <h3 className="dont-break-out" style={{ backgroundColor: '#fff', borderBottom: '2px solid #eee', padding: '20px', fontWeight: '400', fontSize: '2em', margin: '0' }}>
                        {t('page.privacy-policy.title')}
                    </h3>
                    <div className="content" style={{ marginLeft: '0px', padding: '15px' }}>
                        <p>
                            Este sitio web es propiedad de la  <a href="https://nave.is">Náttúrustofa Vestfjarða</a>.
                        </p>
                        <h3>Información del contacto</h3>
                        <ul>
                            <li>Náttúrustofa Vestfjarða</li>
                            <li>Address Dirección: Aðalstræti 12, 415 Bolungarvík, Iceland</li>
                            <li>Teléfono: +354 456 7005</li>
                            <li>Email: gallo [at] nave.is</li>
                        </ul>

                        <h3>Cookies</h3>
                        <p>
                            El cookie (galleta informática) es  "<i>un término que hace referencia a una pequeña
                            información enviada por un sitio web y almacenada en el navegador del usuario, de manera que
                            el sitio web puede consultar la actividad previa del navegador.</i>" según <a href="https://en.wikipedia.org/wiki/HTTP_cookie">Wikipedia</a>.
                        </p>
                        <p>
                            Los usuarios pueden desactivar las cookies o eliminar cualquier cookie individual dentro de la configuración de su navegador.
                        </p>
                        <p>
                        Este sitio web utiliza <i>Google Analytics</i> y <i>Google Maps</i> que sirven las cookies de origen:
                        </p>
                        <ul><li><code>_ga</code></li>
                            <li>_gat_gtag_UA_144115387_1</li>
                            <li><code>_gid</code></li>
                        </ul>
                        <p>
                            y la cookie de terceros:
                        </p>
                        <ul>
                            <li><code>_ga</code></li>
                        </ul>
                        <p>
                            del dominio <code>gstatic.com</code>.
                        </p>
                        <h3>Ubicación GEO</h3>
                        <p>
                            Este sitio web permite a los usuarios obtener su ubicación geográfica
                            actual estimada. Este sitio web no almacena esta ubicación en
                            absoluto y solo se obtiene cuando el usuario lo solicita, presionando
                            un botón.
                        </p>
                        <p>
                            Utiliza la <a href="https://www.w3.org/2008/geolocation/">HTML5 GEO location API</a>.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Última actualización 19 de julio de 2019.
                        </p>
                    </div>
                </main>
            </div >
        );

    }

    return (
        <div className="page">
            <Header url={props.url} />
            <main style={{ margin: '64px auto 3em auto', maxWidth: '800px', padding: '0px', fontSize: '120%' }}>
                <h3 className="dont-break-out" style={{ backgroundColor: '#fff', borderBottom: '2px solid #eee', padding: '20px', fontWeight: '400', fontSize: '2em', margin: '0' }}>
                    {t('page.privacy-policy.title')}
                </h3>
                {/*<p dangerouslySetInnerHTML={
                    { __html: t('page.privacy-policy.content', { interpolation: { escapeValue: false } }) }
                } />
            */}
                <div className="content" style={{ marginLeft: '0px', padding: '15px' }}>
                    <p>
                        This website is owned by <a href="https://nave.is">Náttúrustofa Vestfjarða</a>.
                    </p>
                    <h3>Contact Information</h3>
                    <ul>
                        <li>Náttúrustofa Vestfjarða</li>
                        <li>Address: Aðalstræti 12, 415 Bolungarvík, Iceland</li>
                        <li>Telephone: +354 456 7005</li>
                        <li>Email: gallo [at] nave.is</li>
                    </ul>

                    <h3>Cookies</h3>
                    <p>
                        A cookie is "<i>a small piece of data sent from a website and stored on the user's
                        computer by the user's web browser while the user
                         is browsing</i>" according to <a href="https://en.wikipedia.org/wiki/HTTP_cookie">Wikipedia</a>.
                    </p>
                    <p>
                        Users may disable cookies or delete any individual cookie within their browser's settings.
                    </p>
                    <p>
                        This website uses <i>Google Analytics</i> and <i>Google Maps</i> that serve the first-party cookies:
                    </p>
                    <ul><li><code>_ga</code></li>
                        <li>_gat_gtag_UA_144115387_1</li>
                        <li><code>_gid</code></li>
                    </ul>
                    <p>
                        and the third-party cookie:
                    </p>
                    <ul>
                        <li><code>_ga</code></li>
                    </ul>
                    <p>
                        from the <code>gstatic.com</code> domain.
                    </p>
                    <h3>GEO Location</h3>
                    <p>
                        This website allows users to fetch their estimated current geographic location.
                        This website doesn't store this location at all and it's only fetched when the
                        user asks for it, by pressing a button.
                    </p>
                    <p>
                        It uses the <a href="https://www.w3.org/2008/geolocation/">HTML5 GEO location API</a>.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Last updated 19th of July 2019.
                </p>
                </div>
            </main>
        </div >
    );
};
