import React from 'react';

const markerStyle = {
	Color: 'white',
	background: 'url(/img/markers/marker_red.png) no-repeat center center #3f51b5',
	WebkitTransition: 'all',
	msTransition: 'all',
	backgroundColor: 'transparent',
	display: 'inline-flex',
	border: 'none',
	width: '50px',
	height: '50px',
	textAlign: 'center',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '100%',
	transform: 'translate(-50%, -50%)'
};

function CurrentMarker(props) {
	return (
		<div style={markerStyle}>
			{props.text}
		</div>
	);
}

export default CurrentMarker;