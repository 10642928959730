import { useGlobal } from 'reactn';
//import  {useState} from 'react';

const useCenter = ( c ) => {
	const [center, setCenter] = useGlobal('center');

	function setValue( center ){
        //alert( JSON.stringify(center) );
		setCenter( center );
	}
	return [center,setValue ];
}

export default useCenter;