import { useGlobal } from 'reactn';
//import  {useState,useEffect} from 'react';

const useCurrentPos = () => {
	//const [currentPos, setCurrentPos] = useState({});
	const [ currentPos,setCurrentPos ] = useGlobal('currentPos');

	function setValue( currentPos ){
		setCurrentPos( currentPos );
	}

	return [currentPos,setValue ];
}

export default useCurrentPos;