import { useGlobal } from 'reactn';

const useDrawer = ( ) => {
	const [isDrawerOn, setDrawer] = useGlobal('isDrawerOn');
    
	function toggle(){
		setDrawer( ! isDrawerOn );
	}
	return [isDrawerOn, toggle ];
}

export default useDrawer;