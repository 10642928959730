import React from 'react';
import Overlay from '../overlay/Overlay';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import LocationIcon from '@material-ui/icons/LocationOn';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './style.scss';

function isVisible( id ) {
	let result = ( id === "1" );
	return result;
}

function isMonth( prd ) {
	let d = new Date();
	let n = d.getMonth()+1; // getMonth() starts with 0 and ends with 11.
	let m = prd.split('-');
	let result = ( 1*m[1] >= n && n >= 1*m[0] );
	return result;
}

function handleShow( item ) {
	if( item.hasOwnProperty( 'show') ) {
		return isVisible( item.show );
	}
	if( item.hasOwnProperty( 'prd' ) && item.prd ) {
		return isMonth( item.prd );
	}
	return true;
}


export default function SideBar(props) {

	function getBirdsFromLocationCollection(collection, birds) {
		let tmp = [];
		collection.map(Id => {
			return birds.map(item => {
				if (item.id.trim() === Id.trim() && handleShow( item ) ) {
					tmp.push(item);
				}
				return item;
			});
		});
		return tmp;
	}

	const { t } = useTranslation();

	let birds = props.birds;
	let location = props.location[0];

	if (props.birds.length < 1 || props.location.length < 1) {
		return (
			<div id="sidebar" className="location slider hidden"></div>
		);
	}

	const selectedBirds = getBirdsFromLocationCollection(location.collection, birds);
	const classeSidebar = props.show ? 'location slider slide-in' : 'location fadeInOutx slider slide-out';

	return (
		<React.Fragment>
			<Overlay show={props.show} onClick={props.onSidebarToggle} />
			<div id="sidebar" className={classeSidebar}>
				<div className="location__inner">
					<div className="location__header sticky">
						<h2><div style={{marginTop:'3px', float:'left', marginRight:'10px'}}><LocationIcon /></div> {location.location}</h2>
						<div className="sidebar__close">
							<IconButton onClick={props.onSidebarToggle}><ClearIcon /></IconButton>
						</div>
					</div>
					<p className="location__info">{location.info}</p>
					<strong className="location__detail-title">{t('birds.list.title')}</strong>
					<ul>
						{selectedBirds.slice(0, 1).map(item =>
							<li id={'bird_' + item.id} className="list__item" key={item.id}>
								<img src={item.img} alt="" effect="blur" />
								<h3>{item.title}</h3>
								<p dangerouslySetInnerHTML={{ __html: item.desc }}></p> 
							</li>
						)}
						{selectedBirds.length > 1 && selectedBirds.slice(1, selectedBirds.length ).map(item =>
							<li id={'bird_' + item.id} className="list__item" key={item.id}>
								<LazyLoadImage src={item.img} alt="" effect="blur" />
								<h3>{item.title}</h3>
								<p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
							</li>
						)}
					</ul>
				</div>
			</div>
		</React.Fragment >
	);

}
