import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import trans_en from './locales/en.json';
import trans_de from './locales/de.json';
import trans_es from './locales/es.json';
import trans_is from './locales/is.json';
//import de from 'public/locales/de/translation';
//import Backend from 'i18next-http-backend';


// the translation
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: trans_en
  },
  de: {
    translation: trans_de 
  },
  is: {
    translation: trans_is
  },
  es: {
    translation: trans_es
  }
};

/*
i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  });
*/

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;