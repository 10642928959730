import { useGlobal } from 'reactn';

const useZoom = ( ) => {
	const [zoom, setZoom] = useGlobal('zoom');

	function setValue( zoom ){
		setZoom( zoom );
	}
	return [zoom,setValue ];
}

export default useZoom;