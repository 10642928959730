/**
 * Birdspot
 * Version: 2.6.10
 */
//import React from 'react';
import React, { setGlobal } from 'reactn'; // <-- reactn
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from 'react-router-dom';
import './i18n';
import PagePrivacy from './pages/PagePrivacy';
//import PageFuglar from './pages/PageFuglar';

const DEFAULT_MAP_LAT = 65.85;
const DEFAULT_MAP_LNG = -23.6;
const DEFAULT_MAP_ZOOM = 8;

// Set an initial global state directly:
setGlobal({
  currentPos: {},
  center: { lat:DEFAULT_MAP_LAT, lng:DEFAULT_MAP_LNG},
  zoom: DEFAULT_MAP_ZOOM,
  isDrawerOn : null
});

// https://www.sitepoint.com/react-router-v4-complete-guide/
// https://stackoverflow.com/questions/33754569/multilanguage-support-in-react-router
/*
            <Route path="/de/"  render={({ match: { url } }) => (
    <>
      <Route path={`${url}/`} render= { ({match}) =>( <App {...match} />)} exact />
      <Route path={`${url}/privacy-policy/`} component={PagePrivacy} />
    </>
  )} />
*/
ReactDOM.render( 
//<Suspense fallback="loading">
  
        <Router>
        <Switch>
            <Route path="/" exact render= { ({match}) =>( <Redirect to="/en/"/>)} />
            <Route path="/:lang(de|en|es|is)/" render= { ({match}) =>( <App {...match} />)} exact />
            <Route path="/:lang(de|en|es|is)/privacy-policy/" render= { ({match}) =>( <PagePrivacy {...match} />)} />
            <Route path="/:lang(de|en|es|is)/birds/" render= { ({match}) =>( <App {...match} page="birds"/>)} />
            <Route path="/:lang/lat/:lat/lng/:lng/zoom/:zoom" render= { ({match}) =>( <App {...match} />)} />
            <Route render= { ({match}) =>( <div><h1>Oops! 404 - Page Not Found</h1><p><Link to="/">Home</Link></p></div>)} />
        </Switch>
    </Router>
    //</Suspense>
    , 
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
