import React, { useState} from 'react';
import Overlay from '../overlay/Overlay';

export default function ModalImage(props) {

    const [showLightBox, setLightBox] = useState(false);

    function handleLightBox(e) {
        e.preventDefault();
        setLightBox(!showLightBox);
    }

    return (
        <>
            <a href={props.href} onClick={handleLightBox} aria-label={props.name}>{props.children}</a>
            <Overlay show={showLightBox} onClick={handleLightBox} />
            {showLightBox &&
                <div className="modal-wrapper" onClick={handleLightBox} >
                    <div className="modal-inner">
                        <h3>{props.name}</h3>
                        <img src={props.href} alt="{props.name}" />
                    </div>
                </div>
            }
        </>
    )
}