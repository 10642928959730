import React from 'react';
import { useTranslation } from 'react-i18next';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import useCenter from '../../hooks/useCenter';
import useCurrentPos from '../../hooks/useCurrentPos';
import './style.scss';

export default function MyLocation(props) {
    const { t } = useTranslation();
    const [center, setCenter] = useCenter();
    const [currentPos, setCurrentPos] = useCurrentPos();
    if (center) { };
    if (currentPos) { };

    const handleFindingCurrentPosition = () => {

        navigator.geolocation.getCurrentPosition(
            (position) => {
                let myPosition = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                setCenter(myPosition);
                setCurrentPos(myPosition);
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    console.log("PERMISSION_DENIED");
                }
                console.log(error.message);
                alert(error.message);
            },
            { enableHighAccuracy: true, timeout: 10000, maximumAge: 1000 }
        );
    }

    if ( ! navigator ) {
        return (<React.Fragment></React.Fragment>);
    }

    if ( ! navigator.geolocation) {
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <div className="find-my-location">
            <button name="find-my-location" onClick={handleFindingCurrentPosition}><MyLocationIcon /> <span>{t('location.find')}</span></button>
        </div>
    );
}
