import React, { useState, useEffect } from 'react';
import SimpleMap from './components/simplemap/SimpleMap';
import SideBar from './components/sidebar/SideBar';
import Header from './components/header/Header';
import ModalImage from './components/modalimage/ModalImage';
import MyLocation from './components/mylocation/MyLocation';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ImageIcon from '@material-ui/icons/Image';
import { useTranslation } from 'react-i18next';
import useCenter from './hooks/useCenter';
import useZoom from './hooks/useZoom';
import './App.scss';

const MAP_API_KEY = 'AIzaSyCmZ1t07oqAfnZRq86yLghqstnvp5PKlsM';
const DEFAULT_LANG = 'en';

export default function App(props) {

	const { t, i18n } = useTranslation();

	/*
	const isValidZoom = (input, min, max) => {
		let value = parseInt(input, 10);
		return (!isNaN(value) && value >= min && value <= max);
	}
	*/

	const [locations, setLocations] = useState([]);
	const [birds, setBirds] = useState([]);
	const [locId, setLocId] = useState(0);
	const [lang] = useState(props.params.lang ? props.params.lang : DEFAULT_LANG);
	const [isSideBarOn, setSideBarOn] = useState(false);
	//const [isDrawerOn, setDrawerOn] = useState(false);
	const [center, setCenter] = useCenter();
	const [zoom, setZoom] = useZoom();

	const getDataFromLocId = (locId, _locations) => {
		return _locations.filter(item => {
			return item.id === locId;
		});
		
	}

	const fetchLocations = async (url) => {
		await fetch(url, { cache: "no-cache" })
			.then(res => res.json())
			.then((data) => {
				setLocations(data.locations)
			})
			.catch();
	}

	const fetchBirds = async (url) => {
		await fetch(url, { cache: "no-cache" })
			.then(res => res.json())
			.then((data) => {
				setBirds(data.birds);
			})
			.catch();
	}

	const handleSidebarToggle = () => {
		setSideBarOn(!isSideBarOn);
	}

	const handleChangeLocation = (locId) => {
		handleSidebarToggle();
		setLocId(locId);
	}

	const handleMapChange = ({ center, zoom }) => {
		setCenter({
			lat: center.lat,
			lng: center.lng
		});

		setZoom(zoom);
	};

	// Fit map to its bounds after the api is loaded.
	const onGoogleApiLoaded = (map, maps) => {
	}

	// Sort and filter all birds on the list of all birds in Westfjords.
	const getSortedListBirds = (birds, lang) => {
		let collator = new Intl.Collator('da', { sensitivity: 'variant' });
		return birds.sort(function (a, b) {
			return collator.compare(a.title, b.title);
		});
	}

	function isset(val) {
		return (typeof val !== "undefined");
	}

	function period2Month(prd) {
		if (!isset(prd)) {
			return '';
		}
		if (!prd.indexOf('-')) {
			return prd;
		}
		let m = prd.split('-');
		return t('months.m' + m[0]) + ' - ' + t('months.m' + m[1]);
	}

	useEffect(() => {
		if (lang && lang.length > 0) {
			i18n.changeLanguage(lang);
		}
	}, [props.url, i18n, lang]);

	useEffect(() => {
		let locations_url = "/locales/locations/locations.json";
		fetchLocations(locations_url);
	}, [lang]);

	useEffect(() => {
		let lang_slug = lang.length ? lang : DEFAULT_LANG;
		let birds_url = "/locales/birds/" + lang_slug + ".json";
		fetchBirds(birds_url);
	}, [lang]);

	if (props.page === 'birds') {

		let birdsSorted = getSortedListBirds(birds, lang);

		return (
			<>
				<Header url={props.url} />
				<main>
					<h2 style={{ margin: '100px auto 30px auto', textAlign: 'center' }}>
						{t('page.birds.title')}
						{birdsSorted.length > 0 &&
							' (' + birdsSorted.length + ')' 
						}
					</h2>

					{birdsSorted.length === 0 &&
						<div className="loading" style={{ textAlign: 'center', marginTop: '40px' }}>
							<div className="lds-dual-ring"></div>
						</div>
					}

					{birdsSorted.length > 0 &&
						<table className="birds-list">
							<thead>
								<tr>
									<th scope="col">{t('page.birds.list.header.thumbnail')}</th>
									<th scope="col" className="name">{t('page.birds.list.header.name')}</th>
									<th scope="col" className="prd">{t('page.birds.list.header.period')}</th>
								</tr>
							</thead>
							<tbody>
								{birdsSorted.map(item =>
									<tr id={'bird_' + item.id} className="list__item" key={item.id}>
										<td className="thumbnail">
											{item.img.length > 0 &&
												<div>
													<ModalImage href={item.img} name={item.title}>
														<span className="icon"><ImageIcon /></span>
													</ModalImage>
												</div>
											}
										</td>
										<td className="name">
											<h3>{item.title}</h3>
											<h4><span className="sr-only">{t('page.birds.list.header.latin')} </span>{item.latin}</h4>
											<div className="wrapper">
												<div className="icon"><DateRangeIcon /></div>
												<div className="text">{period2Month(item.prd)}</div>
											</div>

										</td>
										<td className="latin">{item.latin}</td>
										<td className="period">
											{item.prd &&
												<div className="wrapper">
													<div className="icon"><DateRangeIcon /></div>
													<div className="text">{period2Month(item.prd)}</div>
												</div>
											}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					}
				</main>
			</>
		);
	}

	if (!navigator.onLine) {
		return (
			<React.Fragment>
				<Header url={props.url} />
				<MyLocation />
				<main>
					<h2 style={{ margin: '160px auto', textAlign: 'center' }}>{t('site.offline')}</h2>
				</main>
			</React.Fragment>
		);
	}

	return (
		<>
			<Header url={props.url} />
			<MyLocation />
			<main>
				{locations.length > 0 && birds.length > 0 &&
					<SimpleMap
						apiKey={MAP_API_KEY}
						center={center}
						zoom={zoom}
						locations={locations}
						locId={locId}
						onChange={handleMapChange}
						onChangeLocation={handleChangeLocation}
						onGoogleApiLoaded={onGoogleApiLoaded}
					/>
				}
				<SideBar
					show={isSideBarOn}
					locId={locId}
					location={getDataFromLocId(locId, locations)}
					birds={birds}
					onSidebarToggle={handleSidebarToggle}
				/>
			</main>
		</>
	);
}
