import React from 'react';
import './style.scss';

export default function Overlay(props) {
    const classOverlay = props.show ? 'overlay' : 'overlay hidden';

    return (
        <div className={classOverlay} onClick={props.onClick}></div>
    );
};
