import React from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from '../overlay/Overlay';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import './style.scss'

export default function Drawer(props) {
    const { t, i18n } = useTranslation();
    let lang = i18n.language;

    const classDrawer = ( null === props.show ) 
    ? 'slider hidden' 
    : ( 
        props.show 
        ?  'slider slide-in' 
        : 'slider slide-out' 
    ) ;

    return (
        <React.Fragment>
            <Overlay show={props.show} onClick={props.toggleDrawer} />

            <div id="drawer" className={classDrawer}>
                <div className="location__inner">
                    <div className="location__header sticky">
                        <h2><div style={{marginTop:'3px', float:'left', marginRight:'10px'}}><MenuIcon /></div> {t('menu')}</h2>

                        <h3>{t('main.title')}</h3>
                       
                        <div className="sidebar__close">
                            <IconButton onClick={props.toggleDrawer}><ClearIcon /></IconButton>
                        </div>
                    </div>
                    <ul className="menu-list">
                        <li className={props.url === '/' + lang + '/' ? 'current' : '' }><a href={'/' + lang + '/'}>{t('menu.home')}</a></li>
                        <li className={props.url === '/' + lang + '/birds/' ? 'current' : '' }><a href={'/' + lang + '/birds/'}>{t('menu.birds')}</a></li>
                        <li className={props.url === '/' + lang + '/privacy-policy/' ? 'current' : '' }><a href={'/' + lang + '/privacy-policy/'}>{t('menu.privacy-policy')}</a></li>
                    </ul>

                </div>
            </div>
        </React.Fragment>
    );
}